// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyCf0Ub4t5iTIIwVlYbvtaXnOgBXypPrzUQ",
  authDomain: "ten-ten-d471c.firebaseapp.com",
  projectId: "ten-ten-d471c",
  storageBucket: "ten-ten-d471c.appspot.com",
  messagingSenderId: "1085758827175",
  appId: "1:1085758827175:web:2d423ecc9df2738aff0982",
  measurementId: "G-KDJHF6QXMD",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);
const auth = getAuth();

export { db, auth, analytics };
