import React, { useState, useEffect } from "react";
import logo from "./assets/logos/tentenbadge.png";
import secondLogo from "./assets/logos/TenTenLogowithsubtext.png";
import "./App.css";
// down arrow icon
import { FaChevronDown } from "react-icons/fa";
import Form from "react-bootstrap/Form";
// firebase
import { doc, setDoc } from "firebase/firestore";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { db, auth } from "./firebase/firebase";
import { Card, Button } from "react-bootstrap";

function App() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [emailAddress, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setIsError] = useState(false);
  const [success, setIsSuccess] = useState(false);

  // authenticate user then add to users collection
  const handleSubmit = async (e) => {
    e.preventDefault();

    // first check if email is already in use and valid email
    if (
      emailAddress === "" ||
      password === "" ||
      firstName === "" ||
      lastName === ""
    ) {
      setIsError("Please enter an email address.");
      return;
    }

    try {
      // Authenticate user
      await createUserWithEmailAndPassword(auth, emailAddress, password)
        .then(() => {
          // users uid
          const uid = auth.currentUser.uid;
          // add user to users collection
          const docRef = doc(db, "users", uid);
          setDoc(docRef, {
            firstName: firstName,
            lastName: lastName,
            email: emailAddress,
            isAdmin: false,
            isApprovedToSell: false,
            isDealer: false,
            isPrivateSeller: false,
            isVerified: false,
            verifyInProgress: false,
            userAvatar: "",
            uid: uid,
          });
        })
        .catch((error) => {
          alert(error.message);
        });

      setIsSuccess(true);
    } catch (error) {
      alert(error.message);
      setIsError("Something went wrong add your data. Please try again later.");
    }

    // reset form
    setFirstName("");
    setLastName("");
    setEmail("");
    setPassword("");
  };

  // if success show for 2 seconds then hide
  useEffect(() => {
    if (success) {
      setTimeout(() => {
        setIsSuccess(false);
      }, 2000);
    }
  }, [success]);

  // if error show for 2 seconds then hide
  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setIsError(false);
      }, 2000);
    }
  }, [error]);

  return (
    <div className="App">
      <nav
        className="App-nav"
        style={{
          flexDirection: "row",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: 20,
          backgroundColor: "#ede8de",
        }}
      >
        <img
          src={logo}
          className="App-nav-logo"
          alt="logo"
          style={{
            width: "50px",
            height: "60px",
            margin: "0 0 0 0",
            padding: "0 0 0 0",
          }}
        />
        <div />
      </nav>

      <header className="App-header">
        <img src={secondLogo} className="App-logo" alt="logo" />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-end",
            height: "100%",
            width: "100%",
            paddingTop: "15%",
          }}
        >
          <FaChevronDown
            style={{
              color: "#597eb7",
              margin: "0 0 0 0",
              padding: "0 0 0 0",
            }}
          />
        </div>
      </header>
      {/* small write up about ten ten texas, with our goals. */}
      <body className="App-body">
        <div className="App-body-container">
          <div className="text-container-app">
            <p className="body-text-header">
              Secure and <br /> Guaranteed Authentic. Always.
            </p>
          </div>
          <div className="text-container-app">
            <p className="App-body-text" style={{}}>
              X:X (Ten:Ten) Texas is a watch marketplace that connects buyers
              and sellers of luxury watches. We are a Texas-based company that
              is committed to providing a safe and secure platform for our users
              to confidently enjoy their next favorite piece. <br /> <br />
              We are currently in beta and will begin taking new users in the
              coming weeks. Sign-up to be a part of the first group of users to
              experience X:X Texas.
            </p>
          </div>
        </div>
        {/* Sign-up form with first, last and email */}
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Card className="Card-Container-Opt">
            <Card.Body>
              <h3>
                <b
                  style={{
                    color: "#597eb7",
                  }}
                >
                  Join the waitlist
                </b>
              </h3>
              <p
                style={{
                  textAlign: "start",
                  fontSize: "16px",
                }}
              >
                Create your account before we launch to be the first to know
                when we go live.
              </p>
              <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3" controlId="formGroupPassword">
                  <Form.Control
                    type="name"
                    placeholder="First name"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    style={{
                      color: "#597eb7",
                      backgroundColor: "#ede8de",
                      borderWidth: "2px",
                      borderColor: "#597eb7",
                    }}
                  />
                  <Form.Control
                    type="name"
                    placeholder="Last Name"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    style={{
                      color: "#597eb7",
                      backgroundColor: "#ede8de",
                      borderWidth: "2px",
                      borderColor: "#597eb7",
                      marginTop: "10px",
                    }}
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formGroupEmail">
                  <Form.Control
                    type="email"
                    placeholder="Enter email"
                    value={emailAddress}
                    onChange={(e) => setEmail(e.target.value)}
                    style={{
                      color: "#597eb7",
                      backgroundColor: "#ede8de",
                      borderWidth: "2px",
                      borderColor: "#597eb7",
                    }}
                  />
                  <Form.Control
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    type="password"
                    placeholder="Password"
                    style={{
                      color: "#597eb7",
                      backgroundColor: "#ede8de",
                      borderWidth: "2px",
                      borderColor: "#597eb7",
                      marginTop: "10px",
                    }}
                  />
                </Form.Group>

                {/* Button to submit contact */}
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Button
                    className="App-button"
                    type="submit"
                    style={{
                      backgroundColor: "#597eb7",
                      borderColor: "#597eb7",
                      borderWidth: "2px",
                      color: "#ede8de",
                      fontWeight: "bold",
                      fontSize: "18px",
                      padding: "10px 20px 10px 20px",
                      borderRadius: "8px",
                      width: "50%",
                    }}
                  >
                    Submit
                  </Button>
                </div>
              </Form>
            </Card.Body>
          </Card>
          <div
            style={{
              position: "absolute",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "50%",
              height: "100%",
              paddingTop: "37%",
            }}
          >
            {error && (
              <p style={{ color: "red" }}>
                Oopps, looks like there was an error, refresh your page and try
                again.
              </p>
            )}
          </div>
          <div
            style={{
              position: "absolute",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "50%",
              height: "100%",
              paddingTop: "37%",
            }}
          >
            <p>
              {success && (
                <p style={{ color: "green" }}>
                  Welcome to the waitlist! We will be in touch soon.
                </p>
              )}
            </p>
          </div>
        </div>
      </body>

      <footer className="App-footer">
        <img
          src={logo}
          className="App-footer-logo"
          alt="logo"
          style={{
            width: "50px",
            height: "60px",
            margin: "0 0 0 0",
            padding: "0 0 0 0",
          }}
        />

        <div className="footer-column">
          <a
            className="App-link"
            href="mailto:info@tententexas.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            Contact Us
          </a>
        </div>
        {/* contact us */}
        <div className="footer-column"></div>
      </footer>
    </div>
  );
}

export default App;
